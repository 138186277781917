<template>
  <div>
    <p class="account-title">Личная информация</p>

    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
      class="account-info"
    >
      <b-card
        class="d-flex flex-column flex-sm-row align-items-center"
        no-body
        style="padding: 28px 32px; gap: 27px"
      >
        <div
          class="d-flex justify-content-center align-items-center"
          style="
            width: 96px;
            height: 96px;
            background: #ffffff;
            border: 1px solid #cbd2e0;
            border-radius: 12px;
            gap: 80px;
          "
        >
          <span
            style="
              color: #9ca2b1;
              font-weight: 600;
              font-size: 40px;
              line-height: 40px;
            "
          >
            {{ User.self.last_name.slice(0, 1).toUpperCase() }}{{ User.self.first_name.slice(0, 1).toUpperCase() }}
          </span>
        </div>

        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            flex-column flex-sm-row
          "
          style="flex: 1; gap: 32px"
        >
          <div
            class="
              d-flex
              flex-column
              justify-content-center
              align-items-center align-items-sm-start
            "
          >
            <span
              class="text-muted"
              style="font-weight: 400; font-size: 16px; line-height: 22px"
            >
              ID {{ User.self.uuid }}
            </span>
            <span style="font-weight: 600; font-size: 28px; line-height: 32px">
              {{ User.self.last_name }} {{ User.self.first_name }}
            </span>
          </div>
          <div class="chat-icon" />
        </div>
      </b-card>

      <!-- !!! -->

      <div
        class="
          d-flex
          justify-content-between
          flex-column flex-sm-wrap flex-sm-row
          card-info
        "
      >
        <b-card
          class="d-flex w-xl-50-pg w-sm-100"
          no-body
          style="padding: 24px 32px; width: 32.5%"
        >
          <span
            class="text-muted"
            style="
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 2px;
            "
          >
            Внутренняя валюта
            <svg
              style="vertical-align: middle"
              v-b-popover.hover.top="'Тут будет подсказка'"
              class="cursor-pointer info-hover text-muted"
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                d="M9.62199 6.72053L10.0425 6.99109L9.62199 6.72053ZM6.66659 6C6.66659 6.27614 6.89044 6.5 7.16659 6.5C7.44273 6.5 7.66659 6.27614 7.66659 6H6.66659ZM7.99992 9C7.99992 9.27614 8.22378 9.5 8.49992 9.5C8.77606 9.5 8.99992 9.27614 8.99992 9H7.99992ZM8.99992 10.6667C8.99992 10.3905 8.77606 10.1667 8.49992 10.1667C8.22378 10.1667 7.99992 10.3905 7.99992 10.6667H8.99992ZM7.99992 11.3333C7.99992 11.6095 8.22378 11.8333 8.49992 11.8333C8.77606 11.8333 8.99992 11.6095 8.99992 11.3333H7.99992ZM14.6666 8C14.6666 11.4058 11.9057 14.1667 8.49992 14.1667V15.1667C12.458 15.1667 15.6666 11.958 15.6666 8H14.6666ZM8.49992 14.1667C5.09416 14.1667 2.33325 11.4058 2.33325 8H1.33325C1.33325 11.958 4.54188 15.1667 8.49992 15.1667V14.1667ZM2.33325 8C2.33325 4.59425 5.09416 1.83334 8.49992 1.83334V0.833336C4.54188 0.833336 1.33325 4.04196 1.33325 8H2.33325ZM8.49992 1.83334C11.9057 1.83334 14.6666 4.59425 14.6666 8H15.6666C15.6666 4.04196 12.458 0.833336 8.49992 0.833336V1.83334ZM9.33325 6C9.33325 6.16665 9.28486 6.32046 9.20152 6.44997L10.0425 6.99109C10.2265 6.70505 10.3333 6.36423 10.3333 6H9.33325ZM7.66659 6C7.66659 5.53977 8.03968 5.16667 8.49992 5.16667V4.16667C7.4874 4.16667 6.66659 4.98748 6.66659 6H7.66659ZM8.49992 5.16667C8.96016 5.16667 9.33325 5.53977 9.33325 6H10.3333C10.3333 4.98748 9.51244 4.16667 8.49992 4.16667V5.16667ZM7.99992 8.66667V9H8.99992V8.66667H7.99992ZM9.20152 6.44997C9.11757 6.58044 9.01247 6.71542 8.88901 6.86886C8.77078 7.0158 8.63184 7.18424 8.50723 7.3555C8.26153 7.69318 7.99992 8.13383 7.99992 8.66667H8.99992C8.99992 8.46313 9.10011 8.24033 9.31583 7.94385C9.42193 7.79804 9.5386 7.65672 9.66812 7.49576C9.7924 7.3413 9.92718 7.17025 10.0425 6.99109L9.20152 6.44997ZM7.99992 10.6667V11.3333H8.99992V10.6667H7.99992Z"
                fill="#9CA2B1"
              />
            </svg>
          </span>
          <div class="d-flex align-items-center">
            <span style="font-weight: 600; font-size: 24px; line-height: 28px">
              100
            </span>
            <div
              class="currency-icon"
              style="
                background-size: 18px 18px;
                width: 18px;
                height: 18px;
                margin-left: 4px;
              "
            ></div>
          </div>
        </b-card>

        <b-card
          class="d-flex w-xl-50-pg w-sm-100"
          no-body
          style="padding: 24px 32px; width: 32.5%"
        >
          <span
            class="text-muted"
            style="
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 2px;
            "
          >
            Уровень
            <svg
              style="vertical-align: middle"
              v-b-popover.hover.top="'Тут будет подсказка'"
              class="cursor-pointer info-hover text-muted"
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                d="M9.62199 6.72053L10.0425 6.99109L9.62199 6.72053ZM6.66659 6C6.66659 6.27614 6.89044 6.5 7.16659 6.5C7.44273 6.5 7.66659 6.27614 7.66659 6H6.66659ZM7.99992 9C7.99992 9.27614 8.22378 9.5 8.49992 9.5C8.77606 9.5 8.99992 9.27614 8.99992 9H7.99992ZM8.99992 10.6667C8.99992 10.3905 8.77606 10.1667 8.49992 10.1667C8.22378 10.1667 7.99992 10.3905 7.99992 10.6667H8.99992ZM7.99992 11.3333C7.99992 11.6095 8.22378 11.8333 8.49992 11.8333C8.77606 11.8333 8.99992 11.6095 8.99992 11.3333H7.99992ZM14.6666 8C14.6666 11.4058 11.9057 14.1667 8.49992 14.1667V15.1667C12.458 15.1667 15.6666 11.958 15.6666 8H14.6666ZM8.49992 14.1667C5.09416 14.1667 2.33325 11.4058 2.33325 8H1.33325C1.33325 11.958 4.54188 15.1667 8.49992 15.1667V14.1667ZM2.33325 8C2.33325 4.59425 5.09416 1.83334 8.49992 1.83334V0.833336C4.54188 0.833336 1.33325 4.04196 1.33325 8H2.33325ZM8.49992 1.83334C11.9057 1.83334 14.6666 4.59425 14.6666 8H15.6666C15.6666 4.04196 12.458 0.833336 8.49992 0.833336V1.83334ZM9.33325 6C9.33325 6.16665 9.28486 6.32046 9.20152 6.44997L10.0425 6.99109C10.2265 6.70505 10.3333 6.36423 10.3333 6H9.33325ZM7.66659 6C7.66659 5.53977 8.03968 5.16667 8.49992 5.16667V4.16667C7.4874 4.16667 6.66659 4.98748 6.66659 6H7.66659ZM8.49992 5.16667C8.96016 5.16667 9.33325 5.53977 9.33325 6H10.3333C10.3333 4.98748 9.51244 4.16667 8.49992 4.16667V5.16667ZM7.99992 8.66667V9H8.99992V8.66667H7.99992ZM9.20152 6.44997C9.11757 6.58044 9.01247 6.71542 8.88901 6.86886C8.77078 7.0158 8.63184 7.18424 8.50723 7.3555C8.26153 7.69318 7.99992 8.13383 7.99992 8.66667H8.99992C8.99992 8.46313 9.10011 8.24033 9.31583 7.94385C9.42193 7.79804 9.5386 7.65672 9.66812 7.49576C9.7924 7.3413 9.92718 7.17025 10.0425 6.99109L9.20152 6.44997ZM7.99992 10.6667V11.3333H8.99992V10.6667H7.99992Z"
                fill="#9CA2B1"
              />
            </svg>
          </span>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
              <div class="icon-star" style="margin: 0 6px 0 0"></div>
              <span
                style="
                  white-space: nowrap;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 28px;
                "
                >1 <span class="text-muted">из 10</span></span
              >
            </div>
          </div>
          <!-- <div class="test">
            <progress class="level-progress" value="30" max="100"></progress>
          </div> -->
        </b-card>

        <b-card
          class="d-flex w-xl-100"
          no-body
          style="padding: 24px 32px; width: 32.5%"
        >
          <span
            class="text-muted"
            style="
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 2px;
            "
          >
            на сайте
          </span>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
              <span
                style="
                  white-space: nowrap;
                  font-weight: 500;
                  font-size: 24px;
                  line-height: 28px;
                "
              >
                {{ afterRegisterDate(User.self.created_timestamp, new Date()) }}
              </span>
            </div>
          </div>
        </b-card>
      </div>

      <!-- !!! -->

      <div
        class="
          d-flex
          justify-content-between
          flex-column flex-lg-row
          align-items-start
        "
      >
        <b-card
          class="d-flex w-lg-100 card-person"
          no-body
          style="padding: 28px 32px; width: 49%; height: 100%"
        >
          <personal-form-view />
        </b-card>
        <b-card
          class="d-flex w-lg-100"
          no-body
          style="padding: 28px 32px; width: 49%"
        >
          <social-form-view />
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import User from "@/modules/user";
import Utils from "@/modules/utils/";

import personalFormView from "./components/PersonalForm.vue";
import socialFormView from "./components/SocialForm.vue";

export default {
  data() {
    return {
      Utils,
      User,
      is_loading: false,
    };
  },
  components: {
    personalFormView,
    socialFormView,
  },
  methods: {
    afterRegisterDate(after_date, before_date) {
      let diff = Math.floor(before_date.getTime() / 1000 - after_date);
      const day = 86400;

      let days = Math.floor(diff / day);
      let months = Math.floor(days / 30);
      let years = Math.floor(months / 12);

      let message = "";

      if (years > 0) {
        message +=
          years + " " + Utils.declOfNum(years, ["год", "года", "лет"]) + " ";
      }
      months -= years * 12;
      if (months > 0) {
        message +=
          months +
          " " +
          Utils.declOfNum(months, ["месяц", "месяца", "месяцев"]) +
          " ";
      }
      days -= years * 12 * 30 + months * 30;
      if (days > 0)
        message += days + " " + Utils.declOfNum(days, ["день", "дня", "дней"]);
      if (message.length == 0) return "0 дней"

      return message;
    },
  },
  mounted() {
    let a = this.User;
    // debugger
  },
};
</script>

<style lang="scss">
.account-info {
  .card {
    margin-bottom: 16px !important;
  }

  @media screen and (max-width: 576px) {
    .card-info {
      .card {
        margin-bottom: 8px !important;
      }
    }

    .card-person {
      margin-top: 8px;
    }
  }
}
</style>
