<template>
  <validation-observer class="socials-form" ref="socials">
    <p style="font-weight: 600; font-size: 22px; line-height: 28px;">
      Социальные сети
    </p>

    <b-form @submit.prevent="save" class="data-form">

      <section>
        <div class="d-flex justify-content-between mb-2">
          <span class="section-name">
            Ссылки
          </span>
          <a v-if="is_edit_mode" @click="toggleEdit" style="color: var(--link-primary);">
            Отмена
          </a>
          <a v-else @click="toggleEdit" style="color: var(--link-primary);">
            Редактировать
          </a>
        </div>

        <div v-if="is_edit_mode">

          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Вконтакте" rules="">
                  <span class="text-muted" style="font-size: 12px;">Вконтакте</span>
                  <b-form-input placeholder="Вконтакте" v-model="socials.vk" />
                  <small class="text-danger font-small-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Телеграм" rules="">
                  <span class="text-muted" style="font-size: 12px;">Телеграм</span>
                  <b-form-input placeholder="Телеграм" v-model="socials.telegram" />
                  <small class="text-danger font-small-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Instagram" rules="">
                  <span class="text-muted" style="font-size: 12px;">Instagram</span>
                  <b-form-input placeholder="Instagram" v-model="socials.instagram" />
                  <small class="text-danger font-small-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button @click="save" :disabled="!is_can_save" variant="primary" block
            style="height: 56px; font-size: 16px; border-radius: 8px;">Сохранить</b-button>

        </div>

        <div v-else>
          <div class="d-flex flex-column">
            <div class="mb-1" v-for="(soc, key) in socials" :key="key">
              <img alt="" class="network_icon"
                :src="require('@/assets/images/site/icons/networks_icons/' + key + '.svg')">
              <span v-if="soc">{{ soc }}</span>
              <span v-else>-</span>
            </div>
          </div>
          <span> </span>
        </div>
      </section>

    </b-form>
    <div style="width: 100%; height: 1px; background: #CBD2E0;"></div>
  </validation-observer>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { required } from '@validations'

import User from "@/modules/user"

export default {
  data() {
    return {
      User,
      // required,
      is_edit_mode: false,
      is_can_save: true,
      socials: {
        vk: '',
        telegram: '',
        instagram: '',
      }
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    toggleEdit(cancel = true) {
      this.is_edit_mode = !this.is_edit_mode;
      if (!this.edit_mode && cancel) {
        Object.keys(User.self.socials).forEach(key => {
          if (this.socials.hasOwnProperty(key)) {
            this.socials[key] = User.self.socials[key];
          }
        });
      }
    },
    save() {
      this.is_can_save = false;
      this.$refs.socials.validate().then(success => {

        if (!success) {
          this.is_can_save = true;
          return;
        }

        var params = Object.create(this.socials);
        this.$request.post("user.socials.update", {
          'vk': params['vk'],
          'telegram': params['telegram'],
          'instagram': params['instagram'],
        }).then(result => {
          User.get(() => { this.toggleEdit(false); this.is_can_save = true });
          this.$toast({
            component: ToastificationContent,
            props: {
              text: 'Ссылки на социальные сети успешно сохранены',
              icon: 'CheckCircleIcon',
              variant: 'success',
            }
          });
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: 'Ошибка сервера',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            }
          });
          this.is_can_save = true;
        });

      });
    },
  },
  mounted() {
    if (User.self.socials) {
      Object.keys(User.self.socials).forEach(key => {
        if (this.socials.hasOwnProperty(key)) {
          this.socials[key] = User.self.socials[key];
        }
      });
    }
  }
}
</script>

<style lang="scss">
.socials-form {
  .section-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #787E8E;
  }

  section {
    padding: 16px 0px;
  }

  .network_icon {
    width: 24px;
    height: 24px;
    margin-right: 15px;
    background-color: var(--theme-color);
    border-radius: 100px;
  }
}
</style>