<template>
  <validation-observer class="personal-form" ref="personal">
    <p style="font-weight: 600; font-size: 22px; line-height: 28px;">
      Личная информация
    </p>

    <b-form @submit.prevent="save" class="data-form">

      <section id="full_name">
        <div class="d-flex justify-content-between" style="margin: 0 0 6px 0;">
          <span class="section-name">
            Имя Фамилия
          </span>
          <a v-if="edit_mode.full_name" @click="toggleEdit('full_name')" style="color: var(--link-primary);">
            Отмена
          </a>
          <a v-else @click="toggleEdit('full_name')" style="color: var(--link-primary);">
            Редактировать
          </a>
        </div>

        <div v-if="edit_mode.full_name">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Имя" rules="required">
                  <span class="text-muted" style="font-size: 12px;">Имя</span>
                  <b-form-input placeholder="Введите Ваше имя" v-model="personal.first_name" />
                  <small class="text-danger font-small-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Фамилия" rules="required">
                  <span class="text-muted" style="font-size: 12px;">Фамилия</span>
                  <b-form-input placeholder="Введите Вашу фамилию" v-model="personal.last_name" />
                  <small class="text-danger font-small-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button @click="save({ first_name: personal.first_name, last_name: personal.last_name }, 'full_name')"
            :disabled="!is_can_save" variant="primary" block style="height: 56px; font-size: 16px; border-radius: 8px;">
            Сохранить</b-button>
        </div>

        <div v-else>
          <span v-if="personal.first_name || personal.last_name"> {{ personal.first_name }} {{ personal.last_name
          }}</span>
          <span v-else>-</span>
        </div>
      </section>

      <section id="birthday">
        <div class="d-flex justify-content-between" style="margin: 0 0 6px 0;">
          <span class="section-name">
            Дата рождения
          </span>
          <a v-if="edit_mode.birthday" @click="toggleEdit('birthday')" style="color: var(--link-primary);">
            Отмена
          </a>
          <a v-else @click="toggleEdit('birthday')" style="color: var(--link-primary);">
            Редактировать
          </a>
        </div>

        <div v-if="edit_mode.birthday">
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Дата рождения" rules="required">
                  <span class="text-muted" style="font-size: 12px;">Дата рождения</span>
                  <!-- <b-form-input
                      placeholder="Введите Вашу дату рождения"
                      v-model="personal.birthday"
                    /> -->
                  <cleave v-model="personal.birthday" class="form-control" :raw="false" :options="date"
                    placeholder="Введите Вашу дату рождения" />
                  <small class="text-danger font-small-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button @click="save({ birthday: personal.birthday }, 'birthday')" :disabled="!is_can_save"
            variant="primary" block style="height: 56px; font-size: 16px; border-radius: 8px;">Сохранить</b-button>
        </div>

        <div v-else>
          <span v-if="personal.birthday"> {{ personal.birthday }} </span>
          <span v-else>-</span>
        </div>
      </section>

      <section id="email">
        <div class="d-flex justify-content-between" style="margin: 0 0 6px 0;">
          <span class="section-name">
            Электронный адрес
          </span>
          <a v-if="edit_mode.email" @click="toggleEdit('email')" style="color: var(--link-primary);">
            Отмена
          </a>
          <a v-else @click="toggleEdit('email')" style="color: var(--link-primary);">
            Редактировать
          </a>
        </div>

        <div v-if="edit_mode.email">
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Имя" rules="required">
                  <span class="text-muted" style="font-size: 12px;">Электронный адрес</span>
                  <b-form-input placeholder="Введите Ваш электронный адрес" v-model="personal.email" />
                  <small class="text-danger font-small-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button @click="save({ email: personal.email }, 'email')" :disabled="!is_can_save" variant="primary" block
            style="height: 56px; font-size: 16px; border-radius: 8px;">Сохранить</b-button>
        </div>

        <div v-else>
          <span v-if="personal.email"> {{ personal.email }} </span>
          <span v-else>-</span>
        </div>
      </section>

      <section id="phone_number">
        <div class="d-flex justify-content-between" style="margin: 0 0 6px 0;">
          <span class="section-name">
            Номер телефона
          </span>
          <a v-if="edit_mode.phone_number" @click="toggleEdit('phone_number')" style="color: var(--link-primary);">
            Отмена
          </a>
          <a v-else @click="toggleEdit('phone_number')" style="color: var(--link-primary);">
            Редактировать
          </a>
        </div>

        <div v-if="edit_mode.phone_number">
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider #default="{ errors }" name="Имя" rules="required">
                  <span class="text-muted" style="font-size: 12px; margin: 0 0 6px 0;">Номер телефона</span>
                  <!-- <b-form-input
                      placeholder="Введите Ваш номер телефона"
                      v-model="personal.phone_number"
                    /> -->
                  <cleave id="date" v-model="personal.phone_number" class="form-control" :raw="false" :options="phone"
                    placeholder="Введите номер телефона" />
                  <small class="text-danger font-small-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button @click="save({ phone_number: phone_number }, 'phone_number')" :disabled="!is_can_save"
            variant="primary" block style="height: 56px; font-size: 16px; border-radius: 8px;">Сохранить</b-button>
        </div>

        <div v-else>
          <span v-if="personal.phone_number"> {{ personal.phone_number }} </span>
          <span v-else>-</span>
        </div>
      </section>

    </b-form>

  </validation-observer>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import User from "@/modules/user"

export default {
  data() {
    return {
      User,
      required,
      edit_mode: {
        full_name: false,
        birthday: false,
        email: false,
        phone_number: false
      },
      is_can_save: true,
      personal: {
        last_name: null,
        first_name: null,
        middle_name: null,

        birthday: '',
        email: null,
        phone_number: null,

        gender: {
          id: 1,
          name: "Мужской"
        },

        country: {
          id: 181,
          name: "Россия",
          phonecode: 7
        },
      },
      date: {
        date: true,
        delimiter: '.',
        datePattern: ['d', 'm', 'Y'],
      },
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Cleave
  },
  methods: {
    toggleEdit(section, cancel = true) {
      this.edit_mode[section] = !this.edit_mode[section];
      if (!this.edit_mode[section] && cancel) {
        switch (section) {
          case 'full_name':
            this.personal.last_name = User.self.last_name;
            this.personal.first_name = User.self.first_name;
            break;
          case 'birthday':
            this.personal.birthday = User.self.birthday;
            break;
          case 'email':
            this.personal.email = User.self.email;
            break;
          case 'phone_number':
            this.personal.phone_number = User.self.phone_number;
            break;
        }
      }
    },
    save(data, section) {
      this.is_can_save = false;

      this.$refs.personal.validate().then(success => {

        for (const [val, prod] of Object.entries(data)) {
          if (prod.length == 0) {
            this.is_can_save = true;
            return;
          }
        }

        this.$request.post("user.personal.update", data).then(result => {
          User.get(() => { this.toggleEdit(section, false); this.is_can_save = true; });
          this.$toast({
            component: ToastificationContent,
            props: {
              text: 'Персональные данные успешно сохранены',
              icon: 'CheckCircleIcon',
              variant: 'success',
            }
          });
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: 'Ошибка сервера',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            }
          });
          this.is_can_save = true;
        });

      });
    },
  },
  computed: {
    phone() {
      let prefix = `+${User.self.personal.country.phonecode || 7}`;

      return {
        numericOnly: true,
        delimiters: [' (', ') ', '-', '-'],
        prefix: prefix,
        blocks: [prefix.length, 3, 3, 2, 2]
      }
    },
  },
  mounted() {
    if (User.self) {
      Object.keys(User.self).forEach(key => {
        if (this.personal.hasOwnProperty(key)) {
          this.personal[key] = User.self[key];
        }
      });
    }
  }
}
</script>

<style lang="scss">
.personal-form {
  .section-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #787E8E;
  }

  section {
    padding: 16px 0px;
    border-bottom: 1px solid #CBD2E0;
  }
}
</style>